import { Folder } from 'infrastructure/query/folder/types';
import { RelationAnnotation } from 'infrastructure/query/relation/types';
import { Stratum } from 'infrastructure/query/stratum/types';

export const FAIL_VALUE = -9999;
export const INVALID_ADDR = 'about:blank';
export const CONVERT_FAIL = 'about:blank';
export const MAX_ELEVATION_POINT = 1500;

export type Annotation = PointAnnotation | PolylineAnnotation | PolygonAnnotation;
export type AnnotationType = 'points' | 'polylines' | 'polygons' | 'chains' | 'bulkPolygons';

export interface AllAnnotations {
  points?: PointAnnotation[];
  polylines?: PolylineAnnotation[];
  polygons?: PolygonAnnotation[];
  chains?: ChainListItem[];
  chainLines?: PolylineAnnotation[];
}
export interface Position {
  id?: number;
  longitude: number;
  latitude: number;
  altitude?: number;
}

export interface BaseAnnotation {
  id: number;
  name: string;
  description: string;
  color: string;
  positions: Position[];
  data: any;
  coordinate?: string;
  snapshotRelationCreated?: string;
  annotationType?: 'POINT' | 'POLYLINE' | 'POLYGON' | 'EARTHWORK_CROSS_POLYLINE' | 'BULK_POLYGON';
  snapshotRelationId?: number;
  isLinked?: boolean;
  folder?: Folder;
  created?: string;
  createdByName?: string;
  resourceId?: number;
}

export type PointAnnotation = BaseAnnotation;

export interface PolylineAnnotation extends BaseAnnotation {
  calculateData?: {
    length?: number;
    elevation?: Elevation;
  };
  data: { resourceId?: number; csvName?: string };
}
export interface PolygonAnnotation extends BaseAnnotation {
  calculateData?: {
    size?: number;
    perimeter?: number;
  };
}

export interface ChainListItem {
  id?: number;
  color: PolylineAnnotation['color'];
  resourceId: PolylineAnnotation['data']['resourceId'];
  csvName: PolylineAnnotation['data']['csvName'];
}

export const getPolylineLength = (raw: PolylineAnnotation) => raw?.calculateData?.length;
export const getPolylineFixedLength = (raw: PolylineAnnotation) =>
  +raw?.calculateData?.length?.toFixed(3);
export const getStandardElevation = (raw: PolylineAnnotation) => raw?.calculateData?.elevation;
export const checkNeedPolling = (elev: Elevation) => elev?.resultCode === '';

export const getPolygonSize = (raw: PolygonAnnotation) => raw?.calculateData?.size;
export const getPolygonFixedSize = (raw: PolygonAnnotation) =>
  +raw?.calculateData?.size?.toFixed(3);
export const getPolygonFixedPerimeter = (raw: PolygonAnnotation) =>
  +raw?.calculateData?.perimeter?.toFixed(3);

export type ElevationVisibility = { [key in ElevationType]?: boolean };
export type AllElevations = { [K in ElevationType]?: Elevation };

export interface Elevation {
  id: number;
  type: ElevationType;
  resultCode: ResultCode;
  jsonFileUrl: string;
  cadFileUrl: string;
  referResourceIds?: number[];
  /* 비교현장의 SnapshotId */
  referSnapshotId?: number;
  /* get elevation에 사용할 snapshot id */
  elevationRelatedSnapshotId?: number;
  length?: string;
  errorCode?: string;
}

export type ElevationType =
  | 'STANDARD'
  | 'REFER_PLAN_LEVEL'
  | 'REFER_SNAPSHOT'
  | 'MULTIPLE'
  | 'REFER_STRATUM'
  | 'REFER_STRATUM1'
  | 'REFER_STRATUM2'
  | 'REFER_STRATUM3'
  | 'REFER_STRATUM4'
  | 'REFER_STRATUM5'
  | 'REFER_STRATUM6'
  | 'REFER_STRATUM7'
  | 'REFER_STRATUM8'
  | 'REFER_STRATUM9'
  | 'REFER_STRATUM10'
  | 'REFER_STRATUM11'
  | 'REFER_STRATUM12'
  | 'REFER_STRATUM13'
  | 'REFER_STRATUM14'
  | 'REFER_STRATUM15'
  | 'REFER_STRATUM16'
  | 'REFER_STRATUM17'
  | 'REFER_STRATUM18'
  | 'REFER_STRATUM19'
  | 'REFER_STRATUM20'
  | 'REFER_STRATUM21'
  | 'REFER_STRATUM22'
  | 'REFER_STRATUM23'
  | 'REFER_STRATUM24'
  | 'REFER_STRATUM25';

export type ResultCode = '' | 'normal' | 'warning' | 'empty' | 'error';

export type SurfaceType = 'TRIANGULATED' | 'REFER_ALTITUDE' | 'REFER_SNAPSHOT' | 'REFER_PLAN_LEVEL';
export type CalculationStatus = 'REQUIRED' | 'IN_PROGRESS' | 'DONE' | 'ERROR';

export interface ElevationDxf {
  filename: string;
  progressStatus: 'IN_PROGRESS' | 'DONE' | 'ERROR';
  errorCode: string;
  dxfUrl: string;
}

export interface Volume {
  id?: number;
  surfaceType?: SurfaceType;
  referSnapshotId?: number | null;
  referAltitude?: number | null;
  referResourceId?: number | null;
  gsd?: number | null;
  cutError?: number | null;
  cutVolume?: number | null;
  fillError?: number | null;
  fillVolume?: number | null;
  calculationStatus?: CalculationStatus;
  stratumCutVolumeData?: { [stratumType: string]: number };
}
export interface UpdateAnnotationType {
  zoneId: number;
  snapshotId: number;
  annotationType: AnnotationType;
  annotationId: number;
  data: Partial<BaseAnnotation>;
}

export interface UpdatePositionType {
  zoneId: number;
  snapshotId: number;
  annotationType: AnnotationType;
  annotationId: number;
  position: Position;
}

export interface UpdatePolygonType {
  zoneId: number;
  snapshotId: number;
  annotationId: number;
  positions: Position[];
}

export interface ElevationQueryType {
  snapshotId: number;
  annotationId: number;
  referSnapshotId?: number;
  referResourceId?: number;
  elevationType: ElevationType;
  permission: boolean;
}

export interface CreateElevationType {
  snapshotId: number;
  annotationId: number;
  elevationType?: ElevationType;
  data: {
    type: 'STANDARD' | 'MULTIPLE';
    referResourceIds?: number[];
    referSnapshotId?: number;
  };
  permission: boolean;
}

export interface CreateStratumElevationsType {
  snapshotId: number;
  annotationId: number;
  stratumResourceList: Stratum[];
  permission: boolean;
}

export interface CreateChainType {
  name?: string;
  filename?: string;
  lineColor?: string;
}

// TODO: Zone기준 Annotation API 변경 후 삭제 필요
export function getZoneAnnotationType(type: AnnotationType): RelationAnnotation['type'] {
  return {
    points: 'POINT',
    polylines: 'POLYLINE',
    polygons: 'POLYGON',
  }[type];
}

export function getLowerCaseAnnotationType(type: BaseAnnotation['annotationType']): AnnotationType {
  return {
    POINT: 'points',
    POLYLINE: 'polylines',
    POLYGON: 'polygons',
  }[type] as AnnotationType;
}

export type EarthworkCrossingLinesElevations = {
  totalStatus: 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR';
  totalCount: number;
  completedCount: number;
  errorCount: number;
  inProgressCount: number;
  notStartedCount: number;
  annotations: {
    id: number;
    name: string; //TODO: 임시 추가 나중에 확인 필요
    status: 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR';
  }[];
};

export interface BulkPolygon {
  id: number;
  type: 'BULK_POLYGON';
  coordinate: string;
  name?: string;
  color: string;
  data: {
    size: number;
    perimeter: number;
    dtmAreaCreatedAt?: string;
    offsetModification: { type: DtmOffsetModificationType; value? };
    aiToolsObjectId?: string;
  };
  positions: { id: number; latitude: number; longitude: number; altitude: number }[];
}

export interface CreatingBulkPolygon {
  tempId?: number;
  name?: string;
  color: string;
  dtmAreaCreatedAt?: string;
  annotationPositions: { id: number; latitude: number; longitude: number }[];
  offsetModification: { type: DtmOffsetModificationType; value? };
  aiToolsObjectId?: string;
}

export type DtmOffsetModificationType =
  | 'TRIANGULATED' /** 자동 평탄화 */
  | 'BATCH_OFFSET' /** 일괄 조정 */
  | 'FIXED_OFFSET'; /** 사용자 높이 지정 */
