export const MANUAL_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko',
  en: 'https://meissasaas.zendesk.com/hc/en-us',
  ja: 'https://meissasaas.zendesk.com/hc/ja',
};

export const GREEN_MANUAL_URLS = {
  ko: 'https://meissagreen.zendesk.com/hc/en-us',
  en: 'https://meissagreen.zendesk.com/hc/en-us',
  ja: 'https://meissagreen.zendesk.com/hc/en-us',
};

export const EARTHWORK_CROSSING_LINE_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109682105359-3-2-4-%EB%8B%A8%EB%A9%B4%EB%8F%84-%EC%83%9D%EC%84%B1-%EB%B0%8F-%EA%B3%A0%EB%8F%84-%EC%B8%A1%EB%9F%89#:~:text=%EB%AA%85%EC%B9%AD%EC%9C%BC%EB%A1%9C%20%EB%8B%A8%EB%A9%B4%EC%A0%95%EB%B3%B4%EC%97%90%EC%84%9C%20%EB%B3%B4%EC%97%AC%EC%A7%91%EB%8B%88%EB%8B%A4.-,%ED%86%A0%EA%B3%B5%ED%9A%A1%EB%8B%A8%EC%84%A0%20%EC%B6%94%EA%B0%80,-%ED%86%A0%EA%B3%B5%20%ED%9A%A1%EB%8B%A8%EC%84%A0%20%EB%A9%94%EB%89%B4',
  en: '',
  ja: '',
};

export const STRATUM_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8490669170319-2-6-2-%EA%B3%84%ED%9A%8D%EA%B3%A0-%EC%A7%80%EC%B8%B5%EA%B3%A0#:~:text=%EA%BC%AD%20%ED%95%98%EC%8B%9C%EA%B8%B0%20%EB%B0%94%EB%9E%8D%EB%8B%88%EB%8B%A4.-,%EA%B3%84%ED%9A%8D%EA%B3%A0,-%EA%B3%84%ED%9A%8D%EA%B3%A0%20%ED%99%94%EB%A9%B4',
  en: '',
  ja: '',
};

export const CADASTRAL_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/11042985449487-2-5-2-%EC%A7%80%EC%A0%81%EB%8F%84',
  en: '',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/8490669170319-3-2-6-%E8%A8%88%E7%94%BB%E9%AB%98%E3%81%8A%E3%82%88%E3%81%B3%E5%9C%B0%E7%B1%8D%E5%9B%B3%E3%81%AE%E6%B4%BB%E7%94%A8#:~:text=%E8%A8%88%E7%AE%97%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%80%82-,%E5%9C%B0%E7%B1%8D%E5%9B%B3,-*%E7%8F%BE%E5%9C%A8%E3%81%93%E3%81%AE%E6%A9%9F%E8%83%BD',
};

export const HAZARD_AREA_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=%EC%84%A0%ED%83%9D%ED%95%A0%20%EC%88%98%20%EC%9E%88%EC%8A%B5%EB%8B%88%EB%8B%A4.-,3.%20%EC%9C%84%ED%97%98%EA%B5%AC%EC%97%AD,-3%2D1.%20%EC%9C%84%ED%97%98%EA%B5%AC%EC%97%AD',
  en: '',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/11288380854927-2-10-2-%E5%8D%B1%E9%99%BA%E5%8C%BA%E5%9F%9F',
};

export const WORKER_LOCATION_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/8109691311887-3-4-1-Meissa-Guard#:~:text=%EB%B0%9B%EC%9D%84%20%EC%88%98%20%EC%9E%88%EC%8A%B5%EB%8B%88%EB%8B%A4.-,2.%20%EB%85%B8%EB%AC%B4%EC%9E%90%20%EC%9C%84%EC%B9%98%20%EC%A0%95%EB%B3%B4%20%ED%99%95%EC%9D%B8%ED%95%98%EA%B8%B0,-2%2D1.%20%EC%8B%A4%EC%8B%9C%EA%B0%84',
  en: '',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/11288351597967-2-10-3-%E4%BD%9C%E6%A5%AD%E8%80%85%E4%BD%8D%E7%BD%AE%E3%82%84%E5%8B%A4%E6%80%A0%E7%AE%A1%E7%90%86',
};

export const ISSUE_URLS = {
  ko: 'https://meissasaas.zendesk.com/hc/ko/articles/11043148176655-2-8-1-%EC%9D%B4%EC%8A%88',
  en: '',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/11288405339279-2-8-1-%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88',
};

export const VEHICLE_LOCATION_URLS = {
  ko: '',
  en: '',
  ja: 'https://meissasaas.zendesk.com/hc/ja/articles/11288357734159-2-10-4-%E5%BB%BA%E8%A8%AD%E8%BB%8A%E4%B8%A1%E3%81%AE%E4%BD%8D%E7%BD%AE%E3%82%84%E9%81%8B%E8%A1%8C%E8%A8%98%E9%8C%B2',
};
