export const EMAIL_MAX_LENGTH = 100;
export const FULL_NAME_MIN_LENGTH = 2;
export const FULL_NAME_MAX_LENGTH = 20;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 20;
export const COMPANY_MIN_LENGTH = 2;
export const COMPANY_MAX_LENGTH = 30;
export const JOB_TITLE_MAX_LENGTH = 30;
export const PROJECT_NAME_MAX_LENGTH = 30;
export const PROJECT_DESCRIPTION_MAX_LENGTH = 40;
export const COORDINATE_SYSTEM_MAX_LENGTH = 5;
export const ZONE_NAME_MAX_LENGTH = 20;
export const SNAPSHOT_DESCRIPTION_MAX_LENGTH = 40;
export const STRATUM_ALIAS_MAX_LENGTH = 8;
export const BUILDING_NAME_MAX_LENGTH = 20;
export const TWO_FACTOR_MAX_LENGTH = 6;

export const ANNOTATION_NAME_MAX_LENGTH = 20;
export const ANNOTATION_DESCRIPTION_MAX_LENGTH = 100;

export const HAZARD_AREA_NAME_MAX_LENGTH = 20;
export const HAZARD_AREA_DESCRIPTION_MAX_LENGTH = 50;
export const HAZARD_AREA_DATE_MAX_VALUE = '9999-12-31T23:59';

/** 비밀번호 정책: 8자 이상 영문,숫자 조합 필수 */
export function isValidPassword(password: string) {
  return /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/.test(password);
}

/** . 입력을 허용하기 위해서 완전한 숫자타입이 아닐 수 있음 (ex: 3.) 허용 */
export const numberRegex = /-?[0-9]*\.?[0-9]*/g;

/** . 입력을 허용하기 위해서 완전한 숫자타입이 아닐 수 있음 (ex: 3.) 허용, 양수 소숫점 2자리수까지만 입력 가능하도록 제한 */
export const PlanedVolumeNumberRegex = /^[0-9]{0,9}(\.[0-9]{0,2})?$/;
/** 숫자로 유효하지 않은 상태 숫자 뒤에 .만 추가되었거나 .0 이 나타날 수 있음 */
export const inputtingNumberRegex = /-?[0-9]{0,9}\.{1}0*$/g;

export function createNumberRegex(decimalPlaces = 0) {
  if (decimalPlaces === 0) {
    return new RegExp(`^-?\\d*$`);
  }
  // 소수 부분 자릿수 제한을 위한 정규식 패턴을 생성합니다.
  // 예를 들어, decimalPlaces가 2인 경우 소수점 이후 2자리까지만 허용합니다.
  return new RegExp(`^-?\\d+(\\.\\d{0,${decimalPlaces}})?$`);
}
