import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { useMutation, useQuery } from 'react-query';
import { JoinUser, UserInfo } from 'stores/data/types';
import api from './api';
import { keys } from './queryKeys';

export function useUserInfoQuery() {
  return useQuery(keys.info(), () => api.readInfo(), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
  });
}

export function useCompany() {
  return useQuery(keys.company(), () => api.listCompany());
}

export function useUserMutation() {
  const signupMutation = useMutation((data: JoinUser) => {
    const payload = {
      email: data.email,
      password: data.password,
      full_name: data.fullName,
      job_title: data.jobTitle || undefined,
      company: data.company || undefined,
      invite_code: data.inviteCode || undefined,
    };
    return api.create(payload);
  });

  const loginMutation = useMutation(
    (payload: { email: string; password: string }) => api.login(payload),
    {
      onError: () => {
        /** mutation default error option을 끄기 위함. */
      },
    },
  );

  const verificationLogin = useMutation(
    (payload: { email: string; password: string; verificationCode: string }) =>
      api.verificationLogin(payload),
    {
      onError: () => {
        /** mutation default error option을 끄기 위함. */
      },
    },
  );

  const refreshMutation = useMutation((refreshToken: string) => api.refresh(refreshToken));

  const updateInfoMutation = useMutation((data: UserInfo) => api.updateInfo(data));

  const createNewPasswordMutation = useMutation((email: string) => api.createNewPassword(email));

  return {
    signupMutation,
    loginMutation,
    updateInfoMutation,
    refreshMutation,
    createNewPasswordMutation,
    verificationLogin,
  };
}
